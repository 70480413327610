const getSites = async () => {
    let result = null
    try {
        result = await window.axios.get('/v1/sites/portfolio')
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


const getSiteLocations = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/SiteLocation/sitelocations/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getPieraMonitors = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/pieramonitor/monitors/site/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getPieraMonitorById = async (deviceId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/pieramonitor/${deviceId}`)
        let monitor = result.data.data;
        return monitor;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


const updatePieraMonitor = async (pieraMonitor) => {

    try {
        await window.axios.put('/v1/pieramonitor/update', pieraMonitor)
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const deletePieraMonitor = async (pieraMonitor) => {
    let result = null;
  
    let deletePieraMonitorResourceModel = {
      siteId: pieraMonitor.siteId,
      deviceId: pieraMonitor.deviceId
    }
  
    let config = {
      data: deletePieraMonitorResourceModel
    }
    try {
      result = await window.axios.delete(`/v1/pieramonitor/delete/`, config);
      return result.data.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

export default {
    getSites,
    getSiteLocations,
    getPieraMonitors,
    getPieraMonitorById,
    updatePieraMonitor,
    deletePieraMonitor
}