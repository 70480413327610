<template>
  <v-container grid-list-xl>
    <p class="headline pt-3">Edit Piera Monitor</p>
    <validation-observer ref="obs">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6">
              <v-text-field
                v-model="monitor.deviceName"
                label="Device Name"
                disabled
                outlined
              ></v-text-field
            ></v-col>

            <v-col sm="6">
              <validated-text-field
                v-model="monitor.friendlyName"
                label="Friendly Name"
                rules="required"
              ></validated-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col sm="6" class="mt-3">
              <site-location-field
                v-if="finishedLoading"
                :siteId="siteId"
                :selectedSiteLocationId="monitor.siteLocationId"
                @sitelocationselected="siteLocationSelected"
              ></site-location-field>
            </v-col>

            <!-- <v-col sm="6">
              <v-row>
                <v-col sm="7">
                  <validated-select-field
                    :items="endUseTypesWithPercentages"
                    item-value="endUseTypeId"
                    item-text="endUseTypeWithPercentage"
                    rules=""
                    v-model="selectedEndUseType"
                    label="EndUseType"
                    hint="What end use type does this monitor measure"
                    persistent-hint
                    @input="handleSetEndUseType($event)"
                  ></validated-select-field>
                </v-col>
                <v-col sm="5">
                  <v-text-field
                    v-model="selectedEndUseTypePercentage.percentage"
                    label="Percentage"
                    hint="Percent of end usage"
                    persistent-hint
                    type="number"
                    min="0"
                    max="100"
                    step="10"
                    ref="percentText"
                    @input="handleChangePercentValue"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        color="primary"
                        title="Set end use percentage"
                        @click="handleSetEndUseTypePercentage"
                      >
                        Set
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="!valid"
                >Save</v-btn
              >
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-form></validation-observer
    ></v-container
  >
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import SiteLocationField from "@/modules/site/site_location/_components/SiteLocation";
import api from "../_api";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "site-location-field": SiteLocationField,
    "validation-observer": ValidationObserver,
  },

  data() {
    return {
      valid: true,
      monitor: {},
      deviceName: null,
      friendlyName: null,
      siteId: null,
      siteLocations: [],
      finishedLoading: false,
      loading: true,
    };
  },

  async created() {
    this.siteId = this.$route.params.siteId;
    this.monitor = await api.getPieraMonitorById(this.$route.params.deviceId);

    this.finishedLoading = true;
  },

  methods: {
    async submit() {
      try {
        this.monitor.siteId = this.siteId;
        this.monitor.siteLocationId = this.selectedSiteLocation?.id;
        await api.updatePieraMonitor(this.monitor);
        this.$router.push({ name: "PieraIndex" });
      } catch (error) {
        console.log(error);
      }
    },

    siteLocationSelected(selectedSiteLocation) {
      this.selectedSiteLocation = selectedSiteLocation;
    },

    handleCancelCrud() {
      this.$router.push({ name: "PieraIndex" });
    },
  },
};
</script>